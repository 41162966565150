import React from 'react';
import Button from '../components/Button';
import { Column, Container, Row } from '../components/Grid';
import Icon from '../components/Icon';
import Image from '../components/Image';
import Layout from '../components/Layout';
import OutboundLink from '../components/OutboundLink';
import Section from '../components/Section';
import Subfooter from '../components/Subfooter';
import Video from '../components/Video';
import Resource from '../components/Resource';
import { Link } from 'gatsby';
import bgImage from '../images/CPdetail-image-videostill-bkgd-Kim.jpg';
import '../styles/styles.scss';

const PSCareerProfile = () => (
  <Layout title="Health Equity Careers | Associate Director of Pharmaceutical Sciences">
    <Section className="about-mission">
      <Container>
        <Button
          link
          to="/career-profiles"
          className="individual-profile__return"
        >
          &#60; View All
        </Button>
        <h1 className="h1 text-bold">Health Equity Careers</h1>
      </Container>
    </Section>
    <Section className="pt-0">
      <Container fullWidth>
        <Row>
          <Column>
            <div
              className="career-profile__video-wrapper"
              style={{ backgroundImage: `url('${bgImage}')` }}
            >
              <div className="career-profile__link-container">
                <Link
                  className="career-profile__link"
                  to="/manufacturing-scientist"
                >
                  <Icon name="arrowleft" />
                </Link>
              </div>
              <div className="career-profile__video-container">
                <Video guidSrc="c18860ba-6712-46ad-b43b-258f407cd951" />
              </div>
              <div className="career-profile__link-container">
                <Link className="career-profile__link" to="/patient-affairs">
                  <Icon name="arrowright" />
                </Link>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pt-0 pb-0">
      <Container>
        <Row>
          <Column size={8}>
            <h2 className="h2 mt-1">
              Associate Director of Pharmaceutical Sciences
            </h2>
            <p>
              Learn more about this career by exploring the in-depth career fact
              sheet and engage students with hands-on activities for any
              environment.
            </p>
            {/* Quick links */}
            <div className="individual-profile__links">
              Quick Links:&nbsp;
              <Button link to="#about">
                About
              </Button>
              |
              <Button link to="#evaluate">
                Evaluate Your Interest
              </Button>
              |
              <Button link to="#activate">
                Activate Your Interest
              </Button>
            </div>
            <div>
              <Resource
                title="About"
                description="The Associate Director provides guidance to a variety of teams
              in their research facilities and labs to ensure operations are
              running smoothly. Craig developed his love for science-fiction
              as a child into a forward-thinking career in which he looks for
              innovative technology-based solutions to improve the
              manufacturing of medicines and vaccines."
                actions={[
                  {
                    label: 'Career Profile',
                    type: 'dropdown',
                    actions: [
                      {
                        label: 'English',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-AssocDirPharmScience.pdf',
                        type: 'download',
                        title:
                          'Asso Director Pharmaceutical Science Career Profile-English',
                      },
                      {
                        label: 'German',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-AssocDirPharmScience_DE.pdf',
                        type: 'download',
                        title:
                          'Asso Director Pharmaceutical Science Career Profile-German',
                      },
                      {
                        label: 'Spanish',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-AssocDirPharmScience_ES.pdf',
                        type: 'download',
                        title:
                          'Asso Director Pharmaceutical Science Career Profile-Spanish',
                      },
                      {
                        label: 'Portuguese',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-AssocDirPharmScience_PR.pdf',
                        type: 'download',
                        title:
                          'Asso Director Pharmaceutical Science Career Profile-Portuguese',
                      },
                      {
                        label: 'Italian',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-AssocDirPharmScience_IT.pdf',
                        type: 'download',
                        title:
                          'Asso Director Pharmaceutical Science Career Profile-Italian',
                      },
                      {
                        label: 'French',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-AssocDirPharmScience_FR.pdf',
                        type: 'download',
                        title:
                          'Asso Director Pharmaceutical Science Career Profile-French',
                      },
                      {
                        label: 'Chinese',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-AssocDirPharmScience_Chinese.pdf',
                        type: 'download',
                        title:
                          'Asso Director Pharmaceutical Science Career Profile-Chinese',
                      },
                      {
                        label: 'Japanese',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-AssocDirPharmScience_Japanese.pdf',
                        type: 'download',
                        title:
                          'Asso Director Pharmaceutical Science Career Profile-Japanese',
                      },
                      {
                        label: 'Hindi',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-AssocDirPharmScience_HINDI.pdf',
                        type: 'download',
                        title:
                          'Asso Director Pharmaceutical Science Career Profile-Hindi',
                      },
                      {
                        label: 'Russian',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-AssocDirPharmScience_RU.pdf',
                        type: 'download',
                        title:
                          'Asso Director Pharmaceutical Science Career Profile-Russian',
                      },
                    ],
                  },
                ]}
              />
            </div>

            <div className="individual-profile__evaluate mt-1">
              <h3 className="h3" id="evaluate">
                Evaluate Your Interest
              </h3>
              <p>
                Find out more about this career and see how it aligns with
                student interests.
              </p>
              <ul>
                <li>
                  Passionate about the possibilities of using technology to
                  improve health is necessary in this role.
                </li>
                <li>
                  Flexibility is essential for collaborating with others through
                  video conferencing and other methods.
                </li>
                <li>
                  Creative thinking about the future allows new technologies to
                  be integrated into healthcare.
                </li>
                <li>
                  Resilience is needed to overcome failures and learn from
                  mistakes.
                </li>
              </ul>
            </div>

            <div className="individual-profile__activate">
              <Resource
                title="Activate Your Interests"
                description="Bring this career to life with a classroom activity that
              empowers students to identify health inequities in their own
              communities and promote health equity awareness. This activity
              is designed as a companion to the video profile."
                actions={[
                  {
                    label: 'Student Activation',
                    type: 'dropdown',
                    actions: [
                      {
                        label: 'English',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-ComputationalThinkinginAction.pdf',
                        type: 'download',
                        title:
                          'Asso Director Pharmaceutical Science Student Activation-English',
                      },
                      {
                        label: 'German',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-ComputationalThinkinginAction_DE.pdf',
                        type: 'download',
                        title:
                          'Asso Director Pharmaceutical Science Student Activation-German',
                      },
                      {
                        label: 'Spanish',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-ComputationalThinkinginAction_ES.pdf',
                        type: 'download',
                        title:
                          'Asso Director Pharmaceutical Science Student Activation-Spanish',
                      },
                      {
                        label: 'Portuguese',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-ComputationalThinkinginAction_PR.pdf',
                        type: 'download',
                        title:
                          'Asso Director Pharmaceutical Science Student Activation-Portuguese',
                      },
                      {
                        label: 'Italian',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-ComputationalThinkinginAction_IT.pdf',
                        type: 'download',
                        title:
                          'Asso Director Pharmaceutical Science Student Activation-Italian',
                      },
                      {
                        label: 'French',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-ComputationalThinkinginAction_FR.pdf',
                        type: 'download',
                        title:
                          'Asso Director Pharmaceutical Science Student Activation-French',
                      },
                      {
                        label: 'Chinese',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-ComputationalThinkinginAction_Chinese.pdf',
                        type: 'download',
                        title:
                          'Asso Director Pharmaceutical Science Student Activation-Chinese',
                      },
                      {
                        label: 'Japanese',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-ComputationalThinkinginAction_Japanese.pdf',
                        type: 'download',
                        title:
                          'Asso Director Pharmaceutical Science Student Activation-Japanese',
                      },
                      {
                        label: 'Hindi',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-ComputationalThinkinginAction_HINDI.pdf',
                        type: 'download',
                        title:
                          'Asso Director Pharmaceutical Science Student Activation-Hindi',
                      },
                      {
                        label: 'Russian',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-ComputationalThinkinginAction_RU.pdf',
                        type: 'download',
                        title:
                          'Asso Director Pharmaceutical Science Student Activation-Russian',
                      },
                    ],
                  },
                ]}
              />
            </div>
          </Column>
          {/* Side column */}
          <Column size={4} className="pl-3 pt-4 pr-3">
            <Image
              filename="global-image-headshot-Craig@2x.png"
              className="careerProfiles__headshot"
            />
            <div className="individual-profile__holder">
              <div className="individual-profile__quote">
                “I think technology will play a deeper role in pharmaceutical
                development going forward than it does today.”
                <div className="mt-1">Craig Kaftan</div>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pt-0">
      <Container>
        <Row style={{ justifyContent: 'center' }}>
          <Column size={8}>
            <Image filename="global-languages-promo-box@2x.png" />
          </Column>
        </Row>
        <Row className="mt-2">
          <Column size={12}>
            <h3 className="h3">Additional Careers to Explore</h3>
            <p className="ImmaChange">
              Discover the many exciting STEM careers that help support health
              equity globally. Learn what inspires these diverse individuals by
              exploring the below Career Profiles.
            </p>
          </Column>
        </Row>
        <Row className="mt-2">
          <Column size={6} className="pr-1">
            <OutboundLink to="/patient-affairs">
              <Image filename="CPdetail-image-careers-vp.jpg" />
            </OutboundLink>
            <h3 className="h3">
              Vice President and Head of the Center
              <br />
              for Health Equity and Patient Affairs
            </h3>
            <p>
              Find out what is needed to oversee a Center of Excellence focused
              on addressing health inequities from a doctor who is driven by a
              lifelong passion to provide care for others.
            </p>
            <Button to="/patient-affairs" className="button--link">
              Learn more <Icon name="arrowright" marginLeft />
            </Button>
          </Column>
          <Column size={6} className="pl-1">
            <OutboundLink to="/manufacturing-scientist">
              <Image filename="CPdetail-image-careers-MfgScientst.jpg" />
            </OutboundLink>
            <h3 className="h3">Manufacturing Scientist</h3>
            <p>
              Look inside the lab of a scientist who supports manufacturing
              investigations and monitors process performance for an entire
              department.
            </p>
            <Button to="/manufacturing-scientist" className="button--link">
              Learn more <Icon name="arrowright" marginLeft />
            </Button>
          </Column>
        </Row>
      </Container>
    </Section>

    <Subfooter
      className="subfooter__quote-careers-hideQuotes craig-subfooter"
      footerImage="CPdetail-craig-image-QuoteBox.jpg"
      footerText="Automated robotic liquid handlers process a higher volume of work and make lab processes more efficient."
    />
  </Layout>
);

export default PSCareerProfile;
